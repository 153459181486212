.page.contactus{
	.icon{
		text-align: center;
		margin-bottom: 20px;
	}
	p{
		text-align: center;
		margin:0;
		font-size:15px;
		line-height: 1.2em;
	}
	p + p{
		margin-top:5px;
	}
	h4{
		text-align: center;
		font-size:35px;
		font-family:arial;
		color:#d70b2a;
		margin-bottom:80px;
		span{
			margin-top:10px;
			font-size:11px;
			display: block;
		}
	}
	.w960{
		width:1020px;
		position:relative;
		margin:0 auto 80px;
	}
	.form-group {
	    height: 30px;
	    line-height: 30px;
	    &.text{
	    	height: auto;
	    }
	}
	.form-group.required .control-label:after {
	  content:" ※";
	  color:red;
	}
	input.form-control,textarea.form-control{
		border-radius: 0;
		border:0;
		background-color: #eee;
		box-shadow:none;
		resize:none;
		&:focus{
			outline:none;
		}
	}
	input{
		outline:none;
	}
	textarea.form-control{
		height:300px;
	}
	.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
	    margin-left: 0;
	    margin-right:5px;
	    position: static;
	}
	.wpcf7-form-control.radio-inline{
		padding:0;
	}
	.wpcf7-list-item label{
		font-weight: normal;
	}
	hr{
		border:1px solid #eee;
		margin:10px 0;
	}
	.butt{
		height:36px;
	}
	div.wpcf7-response-output{
		color:#d70b2a;
		font-size:11px;
		margin:20px 0;
	}
	span.wpcf7-not-valid-tip{
		color:#d70b2a;
		font-size:11px;
		margin:0;
	}
	div.wpcf7-mail-sent-ng, div.wpcf7-validation-errors {
	    border: 0;
	}
	.btn-default{
		margin-bottom:4px;
		background:#d70b2a;
		box-shadow:0px 4px 0px #8d081c;
        border:0;
        color:#fff;
        min-width: 150px;
        height:40px;
        padding:10px 0 ;
        font-weight: bold;
		&:hover{
			box-shadow:0px 3px 0px #8d081c;
			height:42px;
			margin-bottom:2px;
		}
		&:active{
		  box-shadow:0px 0 0px #8d081c;
		  	height:44px;
			margin-bottom:0;
		}
		&:focus{
			outline:none;
			// margin-bottom:78px;
		}
	}
}