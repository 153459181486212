.page.clients{
	figure{
		text-align: center;
	}
	ul.list{
		padding:0;
		li{
			display: inline-block;
			margin-left:120px;
			max-width:percentage(358/1400);
			img{
				width:100%;
			}
			&:nth-child(3n+1){
				margin-left:30px;
			}
		}
	}
}