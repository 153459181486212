.page.map{

  .center{
    width:1250px;
  }

  #map{
    width: 860px;
    height: 520px;
    display: inline-block;
    margin-bottom:80px;
    position:relative;
    #hint-btn{
      position:absolute;
      top:10px;
      right:10px;
      font-size:15px;
      content:"  "attr(data-title);
      padding:10px 13px;
      background:#fff;
      color:#313131;
      font-family: FontAwesome;
      border-radius:2px;
      box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    }
  }
  nav{
    float:right;
    width: 340px;
    position:relative;
    height:520px;
    .prev,.next{
      background:url(../images/map/arrows-2.png);
      width:40px;
      height:22px;
      display: block;
      margin:0 auto 10px;
    }
    .prev{
      background-position:0 -44px;
      &:hover{
        background-position:0 0;
      }
    }
    .next{
      background-position:0 -66px;
      &:hover{
        background-position:0 -22px;
      }
    }
    aside{
      height:450px;
      overflow: hidden;
      position:relative;
    }
    ul{
      float: right;
      position:relative;
      li{
          padding:13px 13px;
          width:340px;
          height:138px;
          color:#313131;
          font-weight: normal;
          border:1px solid #15b5a4;
          position:relative;
          figure{
            border: 0;
            border-radius: 0;
            padding: 0;
          }
          &.active{
            transition-duration: 0.4s;
            figure:after{
              opacity: 0;
            }
          }

          h4{
            font-size:18px;
            margin:5px 0 0;
            float: right;
            width:150px;
          }
          p{
            font-size:11px;
            margin:0;
            float: right;
            width:150px;
          }
          img{
            width:auto;
            height:100%;
          }
          figure{
            position:relative;
            height:112px;
            width:150px;
            overflow:hidden;
            float: left;
            cursor:pointer;
            &:after{
              width:100%;
              height:100%;
              background:#000;
              opacity:0.5;
              left: 0;
              position: absolute;
              top: 0;
              display: block;
              content:' ';
              z-index:1;
            }
          }
          .more, .location,.highlight{
            color: #15b5a4;
            display: block;
            font-size: 11px;
            margin: 108px 75px 25px;
            position: absolute;
            right: 10px;
            text-align: right;
            top: 0;
            width: 70px;
            span{
              display: inline-block;
              top:2px;
              right:5px;
              height: 15px;
              line-height: 15px;
              width:70px;
              vertical-align: middle;
              transition-duration: 0.2s;
              transition-property: margin color;
              position: absolute;
              z-index: 1;
            }
            &:hover{
              i:before{
                transform: rotateZ(360deg);
              }
              i{
                width:74px;
                // left:47px;
              }
              span{
                color:#fff;
              }
            }
            i{
              color:#fff;
              position:absolute;
              background:#15b5a4;
              border-radius:6.5px;
              width:13px;
              height:13px;
              min-height:13px;
              display: inline-block;
              transition-duration: 0.2s;
              transition-property: width;
              left:-5px;
              top:2px;
              &:before{
                left: 0;
                position: absolute;
                top: 0;
                transition-duration: 0.2s;
                transition-property: transform;
              }
            }
          }
          .location{
            margin: 108px -10px 25px;
          }
          .highlight{
            color: #d70b2a;
            left: 181px;
            margin: 0;
            padding: 0 0 0 80px;
            position: absolute;
            top: 87px;
            i{
              background:#D70B2A;              
            }
            &:hover{
              i{
                width:110%;
              }
            }
          }
      }
      li + li{
        margin-top:13px;
      }
    }
  }

  .inmap{
    h3{
      font-size: 18px;
      margin:20px 0 5px !important;
    }
    p{
      line-height: 1.2em;
      font-size: 11px;
      margin:0 0 5px;
    }
    a{
      color:#15b5a4;
      font-size: 11px;
      display: block;
      width: 70px;
      float: right;
      text-align: right;
      position:relative;
      margin:0 0 25px 60px;
      &.highlight{
        color: #d70b2a;
        float: left;
        margin-left: 5px;
        margin-right: 10px;
        width: auto;
        i{
            margin-top: 2px;
          background:#D70B2A;
        }
        span{
          margin-top:-3px;
          left: 3px;
          margin: 0 7px;
          width: auto;
          white-space: nowrap;
          position:relative;
        }
        &:hover{
          i{
            width: 110%;
          }
        }
      }
      span{
        display: inline-block;
        top:0;
        right:5px;
        height: 15px;
        line-height: 16px;
        width:70px;
        vertical-align: middle;
        transition-duration: 0.2s;
        transition-property: margin color;
        position: absolute;
        z-index: 1;
      }
      &:hover{
        i:before{
          transform: rotateZ(360deg);
        }
        i{
          width:74px;
          // left:47px;
        }
        span{
          color:#fff;
        }
      }
      i{
        color:#fff;
        position:absolute;
        background:#15b5a4;
        border-radius:6.5px;
        width:13px;
        height:13px;
        min-height:13px;
        display: inline-block;
        transition-duration: 0.2s;
        transition-property: width;
        left:-5px;
        top:2px;
        &:before{
          left: 0;
          position: absolute;
          top: 0;
          transition-duration: 0.2s;
          transition-property: transform;
        }
      }
    }
  }
   .inmap-county{
    // background:#000;
      h3{
        font-size:12px;
      }
   }
   .cluster{
    img{
      visibility: hidden;
    }
    div{
      line-height: 44px !important;
      padding-left:3px;
    }
    background:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAoCAMAAAA8EaFZAAABaFBMVEUAAACCxL2CxL2CxL2CxL2CxL2CxL2CxL2CxL19wbp+wrt8wbp9wbp9wbp8wbp5wLh5wLh5wLh4v7h4v7hzvbVturFsubFrubBrubBpuK9ot69ot69mtq5ltq1jtaxjtaxhtKtgtKtfs6pfs6rK5uNdsqlcsqlbsahquLBZsKdVrqVUrqRSraNNq6FLqqBMqqBKqZ9KqZ9Fp53////V6+nT6ug/pJlRraOUzMbi8e9VrqXj8vAsm48tnJAomY0pmo4qmo4nmY0jl4skl4smmIwglYkclIcelYgflYih083r9vUakoUbk4ZTrqQRjoETj4IUj4IWkIMXkYQYkYQclIclmIwGiXsJin0Li34MjH4OjX8RjoEUj4IVkIMclIcmmIwnmY02oJRTrqRuu7Jwu7ODxL2Wzcec0Mui086o1tG13Ni33dm739u94NzA4d3G5OHX7Ora7evi8e/r9vXv+Pf5/Pz9/v7///8ecGWfAAAAVnRSTlMAAgQICgwOEhYXHyYuMDc4QUNGSmKGh4qLkpSXnJ2mp62vsLG1uLm7vsDHyM/V2dnb3OHh4ufp6+vr8fP29/j4+Pn6+vr7/Pz8/Pz9/f3+/v7+/v7+/nCD7SgAAAFoSURBVBgZbcGJIxRxGMfhr2vXmULlSKVLylaELrXl6iJrq8/OEitXOYvw/vvtNWbmZ59HZfU9wxMZMhPDPfUKi/Wn8aX7YzrTniQs2a6yjhTgzW3t/tndmvOAVIeK2haAl6+s5MUzYKFNebWvwfttgY8ejNdKug9sW9g2cFuKfYMNi9qA2Zg6YfnYoo6XoVNDsG6udRhSEnbMtQNJZWHUXH8hK+CfuT4BAg7NdQie3sOBuQ7gncZg01yb8EQ3IXdqUaff4bKaPfhlUc/hS1x6DD9GLOTpnXlISLroweqRBY5WIXNJeQlgZd98+ytAQgUXFslb2zsxs5O9NfK8VhUNUpTN/cxlKRpUScsSUYstKntA1EP5muYJW2rSmQHCBhRoTBNINSrkLoF7Cmv4jO9rgyL68N1SVHyGktm4HNcpuSFX3TQFH+p0zjUKenVezRQwWaMKuoBuVVL9ljfVqugKV1VZ1aMqBf4DJuT1PNd1eEAAAAAASUVORK5CYII=") no-repeat center center;
   }
}