.page{
	margin:0 auto;
	position:relative;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 1.6em;
    color:#313131;
    line-height: 1.2em;
	h3.banner{
		margin:0 0 80px 0;
		width:100%;
		height:170px;
		overflow:hidden;
		img{
			// width:100%;
		}
	}

	.center{
		width:1250px;
		margin: 0 auto;
		position:relative;

	}
	aside.fit{
		width:100%;
		position:relative;
	}
	nav.pager{
		letter-spacing: 0;
		word-spacing: 0;
		margin-bottom:80px;
		margin-top:35px;
		.page-numbers.current{
			color: #fe805b !important;
		}
		a{
		  &.prev,&.next{
		    color:#fe805b !important;
		    display: inline-block!important;
		  }
		  color:#313131 !important;
		  font-size: 13px;
		}
		ul{
		  display: inline-block;
		  li{
		    a{
		      padding:2px;
		      border-radius:0;
		      border:0;
		      color:#313131 !important;
		      &:hover,&:active,&:focus,&.active{
		        background:none !important;
		        color:#fe805b !important;
		      }
		      &:hover{
		        text-decoration: underline;
		      }
		    }
		    >span {
		      padding:2px;
		      border-radius:0;
		      border:0;
		      color:#313131 !important;
			}
		  }
		}
	}
	.page-service{
		color:#d70b2a;
	}
	.page-activities{
		color:#fe805b;
	}
	.activities-list{
		letter-spacing: 0;
		word-spacing: 0;
		width:100%;
		max-width:1250px;
		padding:percentage(30/1400) percentage(0/1400);
		margin:0 auto;
		text-align:center;

		li{
		  background:#fff;
		  padding:percentage(12/1400);
		  display: inline-block;
		  position:relative;
		  width:percentage(430/1400);
		  a{
	  	    display: block;
		    height: 235px;
		    overflow: hidden;
		    position: relative;
		  }
		  img{
		    width:100%;
		    opacity: 0.5;
		    transition-duration: 0.5s;
		  }
		  &:hover img{
		    opacity: inherit;
		  }
		  &:nth-child(3n+1){
		    margin-left:0;
		  }
		  &:nth-child(3) ~ li{
		    margin-top:percentage(50/1400);
		  }
		}
		li + li{
		  margin-left:percentage(38/1400);
		}
		h4{
		    background: #fff none repeat scroll 0 0;
		    bottom: 0;
		    margin: 0;
		    padding-top: 10px;
		    position: absolute;
		    width: 100%;
		    span{
			    color: #313131;
			    display: block;
			    font-size: 16px;
			    line-height: 22px;
			    height:22px;
			    overflow: hidden;
			    position: relative;
			    text-align: left;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    width:290px;
				&:before{
					display:inline-block;
					width:13px;
					height:13px;
					background:#fe805b;
					content: "";
					font-family: FontAwesome;
					line-height: 13px;
					border-radius:50%;
					color:#fff;
					font-size:10px;
					margin-right:8px;
					padding-left:5px;
				}
		    }
			b{
				font-size: 13px;
				font-weight: normal;
				color:#fe805b;
				position:absolute;
			    line-height: 22px;
			    right:0;
			    bottom:0;
			}
		}

	}
}