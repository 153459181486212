$icon-font-path: '../fonts/';

@import "utils/fontello";
@import "utils/cbox";


// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  font: 15px/18px "Lantinghei SC",メイリオ, Meiryo, 'ヒラギノ角ゴ Pro w3', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', STXihei, 'HEITI TC', 黑體-繁, 微軟正黑體, 黑體, Arial, Helvetica, sans-serif,"微軟正黑體",'cwTeXHei', 'cwTeXYen', "Segoe UI","Helvetica Neue", cursive;
  color:#696969;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-style: normal;
  text-rendering: optimizelegibility;
  padding-bottom: 20px;
}

.cwTeXHei{
  font-family: "Lantinghei SC",メイリオ, Meiryo, 'ヒラギノ角ゴ Pro w3', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', STXihei, 'HEITI TC', 黑體-繁, 微軟正黑體, 黑體, Arial, Helvetica, sans-serif,"微軟正黑體",'cwTeXHei', 'cwTeXYen', "Segoe UI","Helvetica Neue", cursive;
  font-weight: bold;
}
.zhenghei{
  font-family: "Lantinghei SC",メイリオ, Meiryo, 'ヒラギノ角ゴ Pro w3', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', STXihei, 'HEITI TC', 黑體-繁, 微軟正黑體, 黑體, Arial, Helvetica, sans-serif,"微軟正黑體",'cwTeXHei', 'cwTeXYen', "Segoe UI","Helvetica Neue", cursive;
  font-weight: bold;
}
.cwTeXYen{
  font-weight: bold;
  font-family: "Lantinghei SC",メイリオ, Meiryo, 'ヒラギノ角ゴ Pro w3', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', STXihei, 'HEITI TC', 黑體-繁, 微軟正黑體, 黑體, Arial, Helvetica, sans-serif,"微軟正黑體",'cwTeXHei', 'cwTeXYen', "Segoe UI","Helvetica Neue", cursive;
}

html[lang=zh-cn]{
  @import 'zh-cn';
}

/* Everything but the jumbotron gets side spacing for mobile first views */
header,
footer {
}
.container{
  width:100%;
  // max-width:1920px;
  min-width: 1250px;
  overflow:hidden;
  padding:0;
  a:focus{
    outline:0;
  }
  a:hover,a:active,a:focus{
    text-decoration: none;
  }

  ul{
    margin: 0;
    padding:0;
    li{
      list-style: none;
    }
  }
  hr{
    margin:0;
    border-color:#c9c9c9;

    &.red{
      border-color:#d70b2a;
    }
  }
  >section,>footer{

    max-width:1250px;
    width: 100%;
    min-width: 1250px;
    margin-left:auto;
    margin-right:auto;
  }
  >section{
    position:relative;
    margin:80px auto;
    font-size:13px;
    text-align:center;
    max-width:1250px;
  }
  h3.title{
    width:100%;
    text-align: center;
    font-size:26px;
    margin-bottom: 30px;
    
    span{
      font-size: 13px;
      color:#313131;
    }
  }

  @import "section/home/highlight";
  @import "section/home/marquee";
  @import "section/home/service";
  @import "section/home/news";
  @import "section/home/clients";
  @import "section/home/activities";

  @import "section/pages/common";
  @import "section/pages/about";
  @import "section/pages/marketing";
  @import "section/pages/management";
  @import "section/pages/map";
  @import "section/pages/news";
  @import "section/pages/clients";
  @import "section/pages/contactus";

}

/* Custom page header */
header {
  font-weight : bold!important;
  color : #313131;
  position:relative;
  height:97px;
  background: #fff;
  z-index: 10;
  border-bottom:1px solid #c9c9c9;

  /* Make the masthead heading the same height as the navigation */
  h1 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 30px;
    margin-left:0;
    position: absolute;
  }
  .center{
    position:relative;
    max-width:1250px;
    width: 100%;
    min-width: 1250px;
    margin-left:auto;
    margin-right:auto;
  }
  .float{
    padding-top:20px;
    position:absolute;
    width:100%;
    left:0;
    top:0;
  }

  nav{
    position:relative;
    i.fa-caret-up{
      z-index: 1;
      position:absolute;
      top:75px;
      right:0;
      width:130px;
      display: block;
      height:3px;
      background-color:#d70b2a;
      &:before{
        position:absolute;
        top:-9px;
        left:62px;
        font-size:13px;
        color:#d70b2a;
      }
      ul{
        opacity:0;
        position:relative;
        z-index: 1;

        li{
          text-align: center;
          background:#d70b2a;
          &:nth-child(1) a{
            position:relative;
            &:before{
              position:absolute;
              content:' ';
              width:100%;
              top:-100px;
              left:0;
              height:100px;
            }
          }
          a{
            text-decoration: none;
            color:#fff!important;
            color:rgba(255,255,255,0.8)!important;
            height:40px;
            width:100px;
            margin: 0 auto;
            display: block;
            padding:13px;
            border-bottom: 1px solid #c9c9c9;
            &:hover,&.active{
              color:rgba(255,255,255,1)!important;
            }
          }
          &:last-child >a{
            border:0;
          }
        }
      }
    }
  }
  .menu >ul{
    margin-right:100px;
    position:relative;
    float: right;

    >li,>li.active{
      width:130px;
      display: inline-block;
      height: 75px;
      margin-top: 10px;
      text-align: center;
      float:left;
      background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2P4zwAAAgEBAOIxSPwAAAAASUVORK5CYII=);

      ul{
        position:relative;
        background:#d70b2a;
        margin-top:-19px;
        float: none;

        li{
          text-align: center;
          background:#d70b2a;
          margin:0;
          &.active{
            height:inherit;
          }
          &:hover,&.active{
            a{
              color:rgba(255,255,255,1)!important;
            }
          }
          a{
            position:relative;
            &:before{
              display: none;
            }
            text-decoration: none;
            color:#fff!important;
            color:rgba(255,255,255,0.8)!important;
            margin: 0 auto;
            display: block;
            height:40px!important;
            padding:13px!important;
            width:100px;
            border-bottom: 1px solid #c9c9c9;
          }
          &:last-child >a{
            border:0;
          }
        }
      }
      a{
        height: 85px;
        display: block;
        position:relative;
        padding-right:0!important;
        &:before{
          height:37px;
          position:absolute;
          top:0;
          width: 0;
          content:' ';
          border-right: 1px solid #c9c9c9;
          right:0;
        }
      }
      a,a.active,a:active,a:hover{
        color: inherit;
        border-radius: 0;
        background:inherit;
        text-align: center;
      }
      >a,>a.active,>a:active,>a:hover{
        padding:0;
      }
      &:last-child >a:after{
        border:0;
      }
      span{
        display:inline-block;
        width:100%;
        text-align: center;
        color:#d70b2a;
        font-size:9px;
        line-height: 15px;
      }
      &.active,&:hover{
        // background:url(../images/header/nav-active.png) center 67px no-repeat;
        a{
          color:#d70b2a;
        }
      }
    }

  }
  #flags{
    display: block !important;
    #sortable{
      position:absolute;
      right: 0;
      top: 0;
      li{
        float:left;
        background:#717171;
        letter-spacing: 1px;
        line-height: 10px;
        padding:0;
        a{
          color:#fff;
          font-size: 13px;
          text-decoration: none;
          vertical-align: middle;
        }
        &.active{
          background:#313131;
        }
      }
    }

  }
  #wpcc_widget_inner{
    position:absolute;
    right: 0;
    top: 0;
    span{
      float:left;
      background:#717171;
      letter-spacing: 1px;
      line-height: 10px;
      padding:5px 12px;
      display: none;
      &#wpcc_zh-cn_link,&#wpcc_zh-tw_link{
        display: block;
        background:#313131;
      }
      a{
        color:#fff;
        font-size: 0;
        text-decoration: none;
        vertical-align: middle;
        text-indent:-999em;

      }
      &#wpcc_zh-cn_link a:after {
        text-indent:999em;
        content:'简中';
        font-size: 13px;
        color:#fff;
        cursor: pointer;
      }
      &#wpcc_zh-tw_link a:after{
        text-indent:999em;
        content:'繁中';
        font-size: 13px;
        color:#fff;
        cursor: pointer;
      }
      &:hover,&.wpcc_current_lang{
        background:#313131;
      }
      &.wpcc_lang#wpcc_zh-cn_link{
        background:#717171;
      }
      &#wpcc_zh-cn_link.wpcc_current_lang ~ #wpcc_zh-tw_link{
        background:#717171;
      }
    }
  }
}

/* Custom page footer */
footer {
  padding-top: 37px;
  color: #777;
  border-top: 1px solid #e5e5e5;
  position:relative;
  a.top{
    font-family: arial;
    font-weight: bold;
    font-size:10px;
    color:#d70b2a;
    cursor: pointer;
    position:absolute;
    right:0;
    top:-20px;
    text-align: center;
    i{
      font-size:26px;
    }
    span{
      display: block;
      margin-top:-10px;
    }
  }
  ul{
    text-align: center;
    li{
      line-height: 24px;
      display: inline;
      a{
        color:#313131;
        i{
          vertical-align: middle;
          filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.1111 0.1111 0.1111 0 0 0.1111 0.1111 0.1111 0 0 0.1111 0.1111 0.1111 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
          filter: gray; /* IE6-9 */
          -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */

          background-image:url(../images/footer-nav.png);
          display: inline-block;
          width:24px;
          height:24px;
          margin-left:15px;
          margin-right:10px;
        }
        span{
          vertical-align: middle;
          line-height: 13px;
          font-size: 13px;
          display: inline-block;
          height:13px;
          padding-right:15px;
          border-right:1px solid #c9c9c9;
        }
        &:hover, &:active, &:focus{
          text-decoration: none;
          i{
            filter:none;
            -webkit-filter: none;
            transition-duration: 0.2s;
          }
        }
      }
      &:nth-child(1){
        a{
          &:hover{
            color:#d70b2a; 
          }
        }
      }
      &:nth-child(2){
        a{
          &:hover{
            color:#fe805b; 
          }
          i{
            background-position:-24px 0;
          }
        }
      }
      &:nth-child(3){
        a{
          &:hover{
            color:#f8b632; 
          }
          i{
            background-position:-48px 0;
          }
        }
      }
      &:nth-child(4){
        a{
          &:hover{
            color:#15b5a4; 
          }
          i{
            background-position:-72px 0;
          }
        }
      }
      &:nth-child(5){
        a{
          &:hover{
            color:#2b75da; 
          }
          i{
            width:10px;
            background-position:-103px 0;
          }
          span{
            border:0;
          }
        }
      }
    }
  }

  h2{
    width:286.804347826087px;
    margin: 35px auto;
    img{
      width:100%;
    }
  }
  p{
    font-size: 10px;
    text-align: center;
    margin-bottom:40px;
  }
}

.container-narrow > hr {
  margin: 30px 0;
}

