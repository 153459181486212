.page.management{

  .m-service{
    color:#d70b2a;
  }

  ul.service-list{
    background:url(../images/management/services.png) no-repeat;
    width:530px;
    margin:0 auto;
    padding:1px 0 20px 84px;

    li + li{
      margin-top:50px;
    }

    h4{
      font-size: 22px;
      color:#313131;  

    }
    h5{
      font-size: 14px;
      color:#898989;
    }
  }
  nav.categories{
    position:relative;
    text-align: center;
    margin-left:310px;
    ul{
      width:100%;
      font-size: 15px;
      li{
        width:165px;
        float: left;
        a{
          color:#535353;
          width:100%;
          display: inline-block;
          height:14px;
          line-height: 12px;
          border-right:1px solid #c9c9c9;
          position:relative;
          text-decoration: none;
        }
        &.active,&:hover{
          a{
            color:#fff;
          }
        } 

        &:last-child a{
          border:0;
        }
      }
    }
    i.fa-caret-down{
      position:absolute;
      width:165px;
      height:30px;
      top:-2px;
      left:0;
      z-index: -1;
      position:absolute;
      content:' ';
      display: block;
      background:#fe805b;
      color:#fe805b;
      &:before{
        line-height: 62px;
      }
    }
  }
  .fit{
    margin-top:45px;
    background:url(../images/home/pattern.png);
  }

  .m-activities{
    color:#fe805b;
  }

}