.news{
  width: 960px;
  h3{
    color: #f8b632;
  }
  >ul{
    max-width:960px;
    margin:0 auto;
    li{
      border-top:1px solid #c9c9c9;
      height:60px;
      line-height: 60px;
      transition-duration:0.25s;
      .date{
        display: inline-block;
        min-width:106px;
        text-align: center;
        color:#313131;
      }
      .category{
        text-align: center;
        margin:0 30px;
        color:#fff;
        padding:1px 8px;
        border-radius:3px;
        a{
          color:#fff;
        }
        &.c-company{
          background:#15b5a4;
        }
        &.c-anounce{
          background:#fe805b;
        }
        &.c-promotion{
          background:#2b75da;
        }
      }
      .subj{
        color:#313131;
        display: inline-block;
        width:620px;
        text-align:left;
      }
      a{
        color:#313131;
      }
      .more{
        color:#f8b632;
        line-height: 8px;
        span{
          display: inline-block;
          height: 9px;
          line-height: 3px;
          vertical-align: middle;
          transition-duration: 0.2s;
          transition-property: margin color;
          position: relative;
        }
        &:hover{
          i:before{
            transform: rotateZ(360deg);
          }
          i{
            width:60px;
          }
          span{
            margin-left:-47px;
            color:#fff;
          }
        }
        i{
          color:#fff;
          position:relative;
          background:#f8b632;
          border-radius:6.5px;
          margin-right:10px;
          width:13px;
          height:13px;
          display: inline-block;
          transition-duration: 0.2s;
          transition-property: width;
          &:before{
            left: -2px;
            position: absolute;
            top: 0;
            transition-duration: 0.2s;
            transition-property: transform;
          }
        }
      }

      &:last-child{
        border-bottom:1px solid #c9c9c9;
      }

      &:hover{
        background:#e9f1fb;

      }
    }
  }
  .btn{
    margin-top:30px;
    margin-bottom:4px;
    color:#fff;
    background:#f8b632;
    border:0;
    padding:10px 45px;
    font-size: 15px;
    box-shadow:0px 4px 0px #f28e00;
    &:hover{
      padding:11px 45px;
      box-shadow:0px 3px 0px #f28e00;
      margin-bottom:2px;
    }
    &:active{
      box-shadow:0px 0 0px #f28e00;
      padding:12px 45px;
      margin-bottom:0;
    }
    &:focus{
      outline:none;
    }
  }
}