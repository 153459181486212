.clients{
  margin-top:80px;
  h3{
    color: #15b5a4;
    margin-bottom:10px;
  }
  ul.clients{
    margin-top:0;
    width:100%;
    li{
      width:percentage(300/1400);
      float: left;
      padding:percentage(10/1400);
      margin-right:percentage(66.66/1400);
      &:nth-child(4n){
        margin:0;
      }
      img{
        width: 100%;
      }
    }
  }
}