/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:url(../images/cbox/overlay.png) repeat 0 0; opacity: 0.9; filter: alpha(opacity = 90);}
#colorbox{outline:0;}
    #cboxTopLeft{width:21px; height:21px; background:url(../images/cbox/controls.png) no-repeat -101px 0;}
    #cboxTopRight{width:21px; height:21px; background:url(../images/cbox/controls.png) no-repeat -130px 0;}
    #cboxBottomLeft{width:21px; height:21px; background:url(../images/cbox/controls.png) no-repeat -101px -29px;}
    #cboxBottomRight{width:21px; height:21px; background:url(../images/cbox/controls.png) no-repeat -130px -29px;}
    #cboxMiddleLeft{width:21px; background:url(../images/cbox/controls.png) left top repeat-y;}
    #cboxMiddleRight{width:21px; background:url(../images/cbox/controls.png) right top repeat-y;}
    #cboxTopCenter{height:21px; background:url(../images/cbox/border.png) 0 0 repeat-x;}
    #cboxBottomCenter{height:21px; background:url(../images/cbox/border.png) 0 -29px repeat-x;}
    #cboxContent{background:#fff; overflow:hidden;}
        .cboxIframe{background:#fff;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxLoadedContent{margin-bottom:28px;}
        #cboxTitle{position:absolute; bottom:4px; left:0; text-align:center; width:100%; color:#949494;}
        #cboxCurrent{position:absolute; bottom:4px; left:58px; color:#949494;}
        #cboxLoadingOverlay{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2P4zwAAAgEBAOIxSPwAAAAASUVORK5CYII=) no-repeat center center;}
        #cboxLoadingGraphic{background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2P4zwAAAgEBAOIxSPwAAAAASUVORK5CYII=) no-repeat center center;}
        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }
        
        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxSlideshow{position:absolute; bottom:4px; right:30px; color:#0092ef;}
        #cboxPrevious{position:absolute; bottom:0; left:0; background:url(../images/cbox/controls.png) no-repeat -75px 0; width:25px; height:25px; text-indent:-9999px;}
        #cboxPrevious:hover{background-position:-75px -25px;}
        #cboxNext{position:absolute; bottom:0; left:27px; background:url(../images/cbox/controls.png) no-repeat -50px 0; width:25px; height:25px; text-indent:-9999px;}
        #cboxNext:hover{background-position:-50px -25px;}
        #cboxClose{position:absolute; bottom:0; right:0; background:url(../images/cbox/controls.png) no-repeat -25px 0; width:25px; height:25px; text-indent:-9999px;}
        #cboxClose:hover{background-position:-25px -25px;}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}





#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxBottomLeft,
#cboxBottomCenter,
#cboxBottomRight,
#cboxMiddleLeft,
#cboxMiddleRight {
  display: none;
}



#cboxContent{
  background: transparent;
  overflow:visible;
}
.cboxPhoto{
  width:100%!important;
  height:auto!important;
  margin:0 auto!important;
}

#cboxTitle{
  text-align: left;
  color: #fff;
  border-bottom:1px solid #c9c9c9;  
  display: inline-block;
  height: 30px;
  position:relative;
  margin-bottom:40px;
  margin-top:-190px;

  &:before{
    display:inline-block;
    width:13px;
    height:13px;
    background:#fe805b;
    content: "";
    font-family: FontAwesome;
    line-height: 13px;
    border-radius:50%;
    color:#fff;
    font-size:10px;
    margin-right:8px;
    padding-left:5px;
    vertical-align: top;
    margin-top:4px;
  }
  b{
    font-weight: normal;
    color:#fe805b;
    position:absolute;
    right:0;
  }
  &:after{

    content:attr(title);
    position:absolute;
    right:0;
    font-size: 13px;
    font-weight: bold;
    color:#fe805b;
  }
}
#cboxClose{
  top:0;
  right:-61px; 
  background:url(../images/cbox/close.png) ; 
  width:41px; height:41px; 
  text-indent:-9999px;
  transform:rotateZ(-360deg);
  transition-duration: 1s;

  &:before,&:after{
    width:2px;
    height:100%;
    position:absolute;
    background:#898989;
    display: block;
    content:' ';
    top:0;
    transform:skewX(45deg) translateX(20px);
  }
  &:hover{
    transform:none;
    &:before,&:after{
      background:#fa7e5a;
    }
  }
  &:after{
    right:0;
    transform:skewX(-45deg) translateX(-20px);
  }
}
#cboxClose:hover{background:url(../images/cbox/close.png);}
#cboxWrapper,#colorbox{overflow:visible;}
#cboxOverlay{background:rgba(0,0,0,.9);}

#cboxPrevious, #cboxNext{
  position:absolute;
  display: block!important;
  background:rgba(0,0,0,0);
  right:-5%;
  left:auto;
  width:30px;
  height:525px;
  top:0;
  &:focus{
    outline:none;
  }

  &:before,&:after{
    width:4px;
    height:20px;
    position:absolute;
    background:#898989;
    display: block;
    content:' ';
    transform:skewX(45deg) translateX(7px);
  }
  &:after{
    right:0;
    transform:skewX(-45deg) translateX(-18px) translateY(2px);
  }
  &:hover{
    &:before,&:after{
      background:#fa7e5a;
    }
  }
}
#cboxPrevious{
  left:-5%;
  &:before{
    transform:skewX(-45deg) translateX(20px);
  }
  &:after{
    right:0;
    transform:skewX(45deg) translateX(-8px) translateY(2px);
  }

}

#cbox{
  a:focus{
    outline:none!important;
  }
  a:hover,a:active,a:focus{
    text-decoration: none;
  }
}

#cboxCurrent{
  margin-top:-110px;
  left:0;
  bottom:auto;
  nav{
    width: 180px * 9 + 20px * 8;
    height:100px;
    max-height: 100px;
    overflow:hidden;
  }
  ul{
    width:20000px;
    margin:0;
    padding:0;
    li{
      float: left;
      list-style:none;
      margin-right:20px;
      position: relative;
      max-height: 100px;
      max-width:180px;
      &:before,&:after{
        content:' ';
        top:0;
        left:0;
        width:100%;
        height:100%;
        display: block;
        position:absolute;
      }
      &:before{
        opacity: inherit;
        background:rgba(0,0,0,.6);
      }
      &:after{
        opacity:0;
        border:1px solid #fe805b;
      }
      &:hover,&.active{
        &:after{
          opacity: 1;
        }
        &:before{
          opacity: 0;
        }
      }
      img{
        height:100px;
      }

      span{
        display: none;
      }
    }
  }
}
#cboxLoadedContent{
  margin-bottom:16px;
}



.map-detail{
  .box-content{
    min-height: 690px;
    background: #fff;
    >section{
      padding-left:770px;
      padding-top:40px;
      padding-right:40px;
      max-height: 700px;
      aside{
        max-height: 650px;
        overflow-y:auto;
        padding-right:30px;
      }
      h4{
        // font-weight:bold;
        font-size:26px;
        margin:0;
        &:after{
          background:url(../images/map/icon.png);
          display: inline-block;
          width:30px;
          height:28px;
          content:' ';
          margin-left:5px;
        }
      }
      span{
        font-size:13px;
      }
      hr{
        margin:10px 0 ;
        border-color:#898989;
      }
      p{
        font-size:13px;
        line-height: 1.4em;
        letter-spacing: 1px;
      }

      p + hr{
        margin-bottom:0;
      }

      table{
        padding-bottom:40px;

        td{
          padding:10px;
          line-height: 1.4em;
          font-size:13px;
          max-width:230px;
          >p,>div,>pre{
            max-width: 210px;
            white-space: normal;
            word-break: break-all;
            word-wrap: break-down;
          }
        }
        td:nth-child(odd){
          background:#eee;
        }
        tr + tr{
          border-top: 1px solid #898989;
        }
      }

      // width:500px;
    }
  }
  #cboxPrevious, #cboxNext, #cboxTitle{
    display: none!important;
  }
  #cboxClose{
    &:hover{
      &:after,&:before{
        background-color:#15b5a4;
      }
    }
  }
  .picture-preview{
    float: left;
    width: 700px;
    height:670px;
    margin-left:50px;
    margin-top:50px;
    overflow: hidden;
    position:relative;
    .picture{
      width:700px;
      height:450px;
      overflow:hidden;
      img{
        width:100%;
        height: auto;
      }
    }
    .picture + i{
      text-transform: normal;
      text-align: center;
      display: block;
      width: 100%;
      line-height: 30px;
      font-size:15px;
    }

    ul{
      width: 10000em;
      padding:0;
      margin: 0 0 0 35px;
      position:absolute;
      bottom:42px;

      li{
        list-style:none;
        float:left;
        width: 145px;
        height:118px;
        overflow: hidden;
        cursor: pointer;
        &.active img{
          border:1px solid #15b5a4;
        }
        &:after{
          display: inline-block;
          width: 100%;
          text-align:center;
          content:attr(title);
          margin-top: 5px;
        }
        img{
          border:1px solid transparent;
          width: 100%;
          height: 100px;
        }
        span{
          display: block;
          color:#313131;
          font-size: 13px;
          text-align:center;
          width:100%;
        }
      }
      li + li{
        margin-left:15px;
      }
    }
    .next, .prev{
      top: 510px;
      width: 25px;
      height:100px;
      display: block;
      position:absolute;
      right:0;
      background:#fff;
      &:after{
        background:url(../images/map/arrows.png) #fff 44px 0;
        content:' ';
        display: block;
        margin-top:30px;
        width:22px;
        height:40px;
      }
      &:hover{
        &:after{
          background-position:0 0;
        }
      }
      &:focus{
        outline:none;
      }
    }
    .prev{
      left:0;
      &:after{
        background-position:-66px 0 ;
      }
      &:hover{
        &:after{
          background-position:-22px;
        }
      }
    }
  }



}


#blueimp-gallery{
  .prev, .next, .close, .title, .indicator{
    display: block;
  }
  .indicator{
    li{
      background-size: 100% auto;
      border-radius: 0;
      width:200px;
      height:300px;
      background-color:rgba(0,0,0,0);
      border:1px solid transparent;
      max-height: 100px;
      max-width:180px;
      &:hover,&.active{
        border:1px solid #fe805b;
      }
    }
    img.slide-content{
      max-height:600px !important;
      width:100% !important;
    }
  }
  .prev, .next, .close{
    background: none;
    border: 0;
    font-size:45px;
    color:#898989;
    // display: inline-block;
    cursor: pointer;
    &:hover{
      text-decoration: none;
      color:#fe805b;
    }
  }
  .next,.prev{
    right:0;
    top:50%;
    margin-top:-15px;
    width: 70px;
  }
  .prev{
    left:0;
  }
  .title{
    width:900px;
    margin:0 auto;
    text-align: left;
    color: #fff;
    border-bottom:1px solid #c9c9c9;  
    left:auto;
    bottom:auto;
    height: 30px;
    font-size: 15px;
    line-height: 18px;  
    margin-bottom:30px;

    &:before{
      display:inline-block;
      width:13px;
      height:13px;
      background:#fe805b;
      content: "";
      font-family: FontAwesome;
      line-height: 13px;
      border-radius:50%;
      color:#fff;
      font-size:10px;
      margin-right:8px;
      padding-left:5px;
      vertical-align: top;
      margin-top:4px;
    }
    b{
      font-weight: bold;
      color:#fe805b;
      position:absolute;
      right:0;
    }
    &:after{

      content:attr(title);
      position:absolute;
      right:0;
      font-size: 13px;
      font-weight: bold;
      color:#fe805b;
    }
  }
  section.overlay{
    width:100%;
    height:100%;
    display: block;
    position:fixed;
    left:0;
    top:0;
  }
  aside.center{
    width:960px;
    height:100%;
    margin:0 auto;
    position:relative;
    display: block;
  }
  .close{
    width: 130px;
    right:-5%;
    top:10%;
    margin-right: 20px;
    transform:rotateZ(-270deg);
    transform-origin:55px 39px 0;
    transition-duration:0.35s;
    transition-timing-function: ease-out;
    i{
      display: inline-block;
      width: 16px;
    }
    &:hover{
      transform:none;
      transition-duration:0.5s; 
    }
  }
  .slide-content{
    min-width:900px;
  }
}

.map{

  #blueimp-gallery{
    .prev, .next, .close, .title, .indicator{
      display: block;
    }
    .indicator{
      li{
        background-size: 100% auto;
        border-radius: 0;
        width:200px;
        height:300px;
        background-color:rgba(0,0,0,0);
        border:1px solid transparent;
        max-height: 100px;
        max-width:180px;
        &:hover,&.active{
          border:1px solid #fe805b;
        }
      }
      img.slide-content{
        max-height:600px !important;
        width:100% !important;
      }
    }
    .prev, .next, .close{
      background: none;
      border: 0;
      font-size:45px;
      color:#898989;
      // display: inline-block;
      cursor: pointer;
      &:hover{
        text-decoration: none;
        color:#fe805b;
      }
    }
    .next,.prev{
      right:0;
      top:50%;
      margin-top:-15px;
      width: 70px;
    }
    .prev{
      left:0;
    }
    .title{
      width:900px;
      margin:0 auto;
      text-align: left;
      color: #fff;
      border-bottom:1px solid #c9c9c9;  
      left:auto;
      bottom:auto;
      height: 30px;
      font-size: 15px;
      line-height: 18px;  
      margin-bottom:30px;

      &:before{
        display:inline-block;
        width:13px;
        height:13px;
        background:#fe805b;
        content: "";
        font-family: FontAwesome;
        line-height: 13px;
        border-radius:50%;
        color:#fff;
        font-size:10px;
        margin-right:8px;
        padding-left:5px;
        vertical-align: top;
        margin-top:4px;
      }
      b{
        font-weight: bold;
        color:#fe805b;
        position:absolute;
        right:0;
      }
      &:after{

        content:attr(title);
        position:absolute;
        right:0;
        font-size: 13px;
        font-weight: bold;
        color:#fe805b;
      }
    }
    section.overlay{
      width:100%;
      height:100%;
      display: block;
      position:fixed;
      left:0;
      top:0;
    }
    aside.center{
      width:960px;
      height:100%;
      margin:0 auto;
      position:relative;
      display: block;
    }
    .close{
      width: 130px;
      right:-5%;
      top:10%;
      margin-right: 20px;
      transform:rotateZ(-270deg);
      transform-origin:55px 39px 0;
      transition-duration:0.35s;
      transition-timing-function: ease-out;
      i{
        display: inline-block;
        width: 16px;
      }
      &:hover{
        transform:none;
        transition-duration:0.5s; 
      }
    }
    .slide-content{
      min-width:900px;
    }
  }

  .fit{
    position:absolute !important;
    top:0;
    left:0;
  }
}

.lg-outer .lg-thumb-item {
    border-color: rgb(13, 10, 10);
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #fe805b;
}
.lg-outer .lg-thumb-item{
  border-radius:0;
}
.lg-thumb.group{
  margin: 0 auto;
}
#lg-download{
  display: none;
}