.activities{
  margin-top:80px;
  margin-bottom: 40px;
  h3{
    color: #fe805b;
    margin-bottom:40px;
  }
  nav{
    position:relative;
    ul{
      width:100%;
      font-size: 15px;
      li{
        width:percentage(140/1400);
        float: left;
        a{
          color:#535353;
          width:100%;
          display: inline-block;
          height:14px;
          line-height: 12px;
          border-right:1px solid #c9c9c9;
          position:relative;
          text-decoration: none;
        }
        &.active,&:hover{
          a{
            color:#fff;
          }
        } 

        &:last-child a{
          border:0;
        }
      }
    }
    i.fa-caret-down{
      position:absolute;
      width:percentage(140/1400);
      height:30px;
      top:-5px;
      left:0;
      z-index: -1;
      position:absolute;
      content:' ';
      display: block;
      background:#fe805b;
      color:#fe805b;
      &:before{
        line-height: 62px;
      }
    }
  }

  &.content{
    margin-top: 40px;
    max-width:627px;
    min-width:627px;
    position:relative;
    .next,.prev{
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
      &:hover{
       filter:none;
        -webkit-filter: none;
        transform:translateX(2px);
        transition-duration: 0.2s;
      }
      position:absolute;
      right:7px;
      top:0;
      padding-top:185px;
      width:22px;
      height:100%;
      display: block;
      opacity:0.5
    }
    .prev{
      left:7px;
      &:hover{
        transform:translateX(-2px);
      }
      img{
        transform: rotateZ(-180deg);
      }
    }
    &:hover{
      .next,.prev{
        opacity: inherit;
      }
    }
    >ul{
      background:url(../images/home/pattern.png);
      height: 410px;
      width:60000px;
      padding-left:588px*3;
      padding-right:588px*3;
      margin-left:-627px*3;
      position:relative;
      display: block;
      left: 0;
      li{
        a{
          min-width:500px;
          max-width:500px;
          display:block;
          background-position:center top;
          background-size:100% auto;
          position:relative;
        }
        float: left;
        padding: 16px 16px 5px;
        margin:35px 28px;
        background:#fff;
        display: block;
        transition-duration:0.2s;
        &:hover{
          transform:scale(1.02);
        }
        img{
          display: block;
          width:100%;
          height:280px;
        }
        span{
          display: block;
          text-align: left;
          color:#313131;
          position:relative;
          line-height: 70px;
          background:#fff;
          width:100%;
          height:60px;
          &:before{
            display:inline-block;
            width:13px;
            height:13px;
            background:#fe805b;
            content: "";
            font-family: FontAwesome;
            line-height: 13px;
            border-radius:50%;
            color:#fff;
            font-size:10px;
            margin-right:8px;
            padding-left:5px;
          }
          label{
            width: 405px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 60px;
            font-weight:normal;
            position:absolute;
          }
        }
        b{
          bottom: 15px;
          color: #fe805b;
          font-weight: normal;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}