.page.about{
  .features{
    margin:0 auto;
    width:937px;
    white-space: nowrap;
    font-size:20px;
    letter-spacing:1px;
    word-spacing: 0;
    li{
      display: inline-block;
      width:25%;
      height:155px;
      font-weight:bold;
      // float: left;
      text-align: center;
      span{
        margin-top:20px;
        display: block;
        width: 100%;
      }
      &:nth-child(1){
        margin-left:-25px;
      }
    }
    margin-bottom:80px;
  }
  p{
    width:937px;
    margin:0 auto 30px;
  }
  .qa{
    width:960px;
    margin:10px auto 80px;
    letter-spacing: 0;
    word-spacing: 0;
    background: url(../images/about/qa-background.png) no-repeat;
    h6{
      font-size:20px;
      color:#d70b2a;
      font-weight: bold;
      margin:20px 0 0;
    }
    p{
      font-size:15px;
      color:#fff;
      font-weight: bold;
      display: inline-block;
    }
    li{
      position: relative;
      display: inline-block;
      text-align:left;
      padding-left:40px;
      padding-bottom:1em;
      width:auto;
      vertical-align: middle;
      &:nth-child(2){
        padding-left:60px;
      }
      &:nth-child(3){
        padding-left:50px;
      }
    }
  }

  .qa ~ hr{
    width:100%;
    max-width:1400px;
  }

  h4{
    color:#d70b2a;
    font-size:26px;
    text-align:center;
    margin:80px auto 115px;
  }

  .guarantees{
    background:url(../images/home/pattern.png);
    width:100%;
    position:relative;
    height:415px;
    ul{
      width:1000px;
      left:50%;
      top:-50px;
      margin-left:-500px;
      line-height: 170px;
      letter-spacing: 0px;
      word-spacing: 0px;
      position:absolute;
      li{
        width:250px;
        height:166px;
        display: inline-block;
        margin:0 38px;
        position:relative;
        box-shadow:3px 3px 0 rgba(137,137,137,0.35);
        overflow:hidden;

        img{
          vertical-align: top;
        }
        h5, h6{
          position:absolute;
          text-align: center;
          left:0;
          text-align:center;
          width:100%;
          margin:0;
        }
        h5{
          top:70px;
          font-size: 23px;
          font-weight: bold;
        }
        h6{
          font-size: 18px;
          transform:scale(10);
          opacity: 0;
          top:95px;
          color:#fff;
        }
        &:before, &:after{
          display: block;
          content:' ';
          position:absolute;
        }
        &:before{
          background:rgba(255,255,255,.5);
          width:220px;
          height:142px;
          left:16px;
          top:13px;
        }
        &:after{
          height:100%;
          width:100%;
          left:0;
          top:0;
          border:0px solid rgba(215,11,42,0);
        }
        &:hover{
          &:before{
            background:rgba(215,11,42,.2);
            transition-duration: 0.2s;
            width:100%;
            height:100%;
            top:0;
            left:0;
          }
          &:after{
            border:6px solid rgba(215,11,42,1);
            transition-delay: 0.2s;
            transition-duration: 0.2s;
          }
          h5{
            top:56px;
            color:#fff;
            transition-duration: 0.2s;
          }
          h6{
            opacity:1;
            transform: none;
            transition-delay: 0.2s;
            transition-duration: 0.2s;
          }
        }
      }
    }
  }

  .guarantees ~ h5{
    color:#313131;
    font-size:15;
    line-height: 1.4em;
    width:320px;
    margin:80px auto 36px;
    text-align:center;
  }
  .guarantees ~ h5 ~ figure{
    text-align:center;
    margin:0 auto 80px;
  }
}