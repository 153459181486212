.marquee{
  position:relative;
  background:#fbe6e9;
  height:30px;
  font-size: 13px;
  line-height: 32px;
  display: block;
  margin-top:0;
  i.fa-volume-down{
    color:#fff;
  }
  i.fa-caret-right{
    color:#d70b2a;
    position:absolute;
    right:-4px;
    margin-top:8px;
  }
  .w150{
    position:relative;
    width:percentage(150/1400);
    height:30px;
    background-color:#d70b2a;
    color:#fff;
    text-align: center;
    float: left;
  }
  .mq{
    width:percentage((1400-150)/1400);
    float: left;
    display: inline-block;
    position:relative;
    overflow:hidden;
    ul{
      width:100%;
      height:30px;
      position:relative;
      li{

        
        /* required styles */
        position: absolute;
        margin-left:10px;
        top: -999em;
        left: 0;
        display: block;
        white-space: nowrap; /* keep all text on a single line */
        a{
          color:#313131;
        }
      }
    }
  }

}