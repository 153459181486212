.highlight{
  position:relative;
  margin:0 auto;
  .next,.prev{
    position:absolute;
    top:0;
    margin-top:200px;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    &:hover{
     filter:none;
      -webkit-filter: none;
    }
    padding:30px;
    display: block;
    img{
      display: block;
    }
  }
  .next{
    right:0;
    margin-right:percentage(-5/1440);
  }
  .prev{
    left:0;
    margin-left:percentage(-5/1440);
    img{
      transform: rotateZ(180deg);
    }
  }
  nav{
    top:0;
    width:100%;
    ul{
      width:100%;
      display: block;
      text-align: center;
      bottom:10px;
      position:absolute;
      letter-spacing: 4px;
      li{
        display: inline-block;
        a{
          display: block;
          width:10px;
          height:10px;
          border-radius:50%;
          text-indent: -999em;
          background: #c9c9c9;

        }
        &.active,&:hover{
          a{
            background: #d70b2a;
          }
        }
        &:hover{
          opacity:0.5;
          &.active{
            opacity: inherit;
          }
        }
      }
    }
  }
  ul{
    width:100%;
    padding:percentage(500/1400) 0 0;
    margin:0;
    li{
      display: none;
      list-style: none;
      img{
        display: block;
        width:100%;
      }
    }

  }

  .cur,.fade{
    background:#000;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}